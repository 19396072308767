<template>
  <div>
    <Modal id="confirmationModal" v-if="showConfirm">
      <h1 class="text-blue-800 text-3xl font-bold my-3">
        Recomendación enviada exitosamente
      </h1>
      <p class="my-8">
        La recomendación denominada <b>NOMBRE DE LA RECOMENDACIÓN</b>, a la
        entidad <b>NOMBRE DE LA ENTIDAD</b> se ha enviado de manera exitosa. Una
        vez la entidad vigilada relacione sus recomendaciones se le notificara
        para su seguimiento.
      </p>
      <div class="flex gap-3 justify-center my-3x capitalize">
        <BaseButton @click.native="closeModal" label="ENTENDIDO" /></div
    ></Modal>
    <header class="mb-4 items-center justify-center gap-3">
      

        <a-row type="flex" justify="space-between" layout="vertical">
          <a-col :lg="{span:8}" :md="{span:10}" :xl="{span:6}" :sm="{span:24}">
            <a-button type="primary" shape="round" :block="true" icon="control" class="w-60" @click="showDrawer">
              Filtros de búsqueda
            </a-button>
          </a-col>
          <a-col :lg="{span:8}" :md="{span:10}" :xl="{span:6}" :sm="{span:24}">
            <a-button type="primary" shape="round" :block="true" icon="plus" class="w-60"
              @click="$router.push('./new_recommendation')">
              Nueva recomendación
            </a-button>
          </a-col>
          <a-col :xl="{span:8, offset:4}" :sm="{span:24}">
            <div class="w-full sm:max-w-sm flex gap-3">
              <label for="table-search" class="sr-only">Search</label>
              <div class="relative w-full">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"></path>
                  </svg>
                </div>
                <a-input-search
                  placeholder="Buscar por descripción, evento o hallazgo, causa raíz, justificación o descripción de cierre."
                  class="rounded-full" style="width: 100%" v-model="user" />
              </div>
              <a-button class="w-4 h-8 flex justify-center items-center" type="primary" :block="true"
                @click="onSearchUser">
                <a-icon type="search" :style="{ fontSize: '18px' }" class="font-normal" />
              </a-button>
            </div>
          </a-col>
        </a-row>

        <div v-if="loading" class="flex justify-center items-center">
          <a-spin>
            <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
          </a-spin>
        </div>

      <a-drawer :placement="placement" :closable="false" :visible="visible_drawer" @close="onClose" width="456">
        <a-space size="small" style="font-size: large;">
          <a-icon type="control" />
          <span class="mx-1" style="color: gray;">Filtros de búsqueda</span>
        </a-space>
        <a-divider />

        <a-form :form="form" layout="vertical" hide-required-mark>

          <a-row type="flex" justify="space-between" :gutter="[0, 12]"
            style="height: calc(78vh); overflow-y: auto; overflow-x: hidden;">
            <a-col :span="24">
              <a-form-item label="Palabra clave" class="mb-0">
                <a-input-search placeholder="Escribe aquí..." @search="onSearch" style="border-radius: 0%;" />
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <BaseSelect ref="Sector" id="Sector" label="Sector" :selectOptions="optionsFavorability"
                :disabled_margin="true" />
            </a-col>
            <a-col :span="11">
              <BaseSelect ref="Entidad" id="Entidad" label="Entidad" :selectOptions="optionsFavorability"
                :disabled_margin="true" />
            </a-col>
            <a-col :span="11">
              <BaseSelect ref="Entidad" id="Entidad" label="Entidad" :selectOptions="optionsFavorability" />
            </a-col>
            <a-col :span="11">
              <BaseSelect ref="Entidad" id="Entidad" label="Entidad" :selectOptions="optionsFavorability" />
            </a-col>
            <a-col :span="11">
              <BaseSelect ref="Entidad" id="Entidad" label="Entidad" :selectOptions="optionsFavorability" />
            </a-col>
            <a-col :span="11">
              <BaseSelect ref="Entidad" id="Entidad" label="Entidad" :selectOptions="optionsFavorability" />
            </a-col>
            <a-col :span="11">
              <a-form-item label="Tiene plan de acción" class="mt-2">
                <a-checkbox-group v-model="checkedList_action_plan" :options="['Sí', 'No']" />
              </a-form-item>
            </a-col>
            <a-col :span="11" v-if="checkedList_action_plan.includes('Sí')">
              <a-form-item label="Tiene reporte de avance" class="mt-2">
                <a-checkbox-group v-model="checkedList_progress_report" :options="['Sí', 'No']" />
              </a-form-item>
            </a-col>

            <a-col :span="23">
              <a-form-item label="Porcentaje de avance del plan de acción" class="mt-2">
                <a-slider range :default-value="[0, 100]" :disabled="disabled" :marks="{ 0: '0', 50: '50', 100: '100' }" />
              </a-form-item>
            </a-col>

            <a-col :span="24">
              <a-form-item label="Termino de respuesta de la EV a la recomendación" class="mb-0">
                <a-input-search placeholder="Escribe aquí..." @search="onSearch" style="border-radius: 0%;" />
              </a-form-item>
            </a-col>

            <a-col :span="24">
              <a-form-item label="Fecha de creación de la recomendación" class="mb-0">
                <a-radio-group v-model="value_radio">
                  <a-radio :value="1" class="mr-10">Una sola fecha</a-radio>
                  <a-radio :value="2">Periodo de fechas</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :span="14" v-if="value_radio == 1">
              <a-form-item label="Producto" class="mb-0">
                <a-date-picker></a-date-picker>
              </a-form-item>
            </a-col>
            <a-col :span="11" v-if="value_radio == 2" class="gutter-row">
              <a-form-item label="Desde" class="mb-0">
                <a-date-picker></a-date-picker>
              </a-form-item>
            </a-col>
            <a-col :span="11" v-if="value_radio == 2" class="gutter-row">
              <a-form-item label="Hasta" class="mb-0">
                <a-date-picker></a-date-picker>
              </a-form-item>
            </a-col>


          </a-row>

        </a-form>

        <div :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          height: 'calc(8vh)',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 2
        }">
          <a-button :style="{ marginRight: '8px' }" @click="onClose">
            Cancel
          </a-button>
          <a-button type="primary" @click="onClose">
            Submit
          </a-button>
        </div>
      </a-drawer>
    </header>
    <Wrapper class="w-full">
      <div class="flex gap-3">
        <a-icon type="search"></a-icon>
        <h2 class="text-left text-md text-gray-600">
          <b>Filtros de búsqueda</b>
        </h2>
      </div>
      <div class="flex gap-4">
        <BaseSelect
          label="Entidad"
          :selectOptions="optionsCompanies"
          v-model="company"
        />
        <BaseSelect
          label="Estado"
          :selectOptions="statuses"
          v-model="status"
        />
        <BaseSelect
          label="Estado de respuesta"
          :selectOptions="answer_statuses"
          v-model="answer_status"
        />
        <BaseDate
            label="Fecha de formulación"
            v-model="formulation_date"
        />
        <a-button
          class="w-4 h-8 flex justify-center items-center mt-auto"
          type="primary"
          :block="true"
          @click="onSearch"
        >
          <a-icon
            type="search"
            :style="{ fontSize: '18px' }"
            class="font-normal"
          />
        </a-button>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import Wrapper from "../../../UI/Wrapper.vue";
export default {
  props: {
    showButton: Boolean,
    loading: Boolean,
    search: String,
  },
  mounted() {
    this.getParamsHandler();
    this.getRecommendationsStatus();
  },
  methods: {
    showDrawer() {
      this.visible_drawer = true;
    },
    onClose() {
      this.visible_drawer = false;
    },
    closeModal() {
      this.showConfirm = false;
    },
    onSearchUser(value) {
      this.$emit("search-term", {
        search: this.user,
      });
    },
    onSearch(value) {
      this.$emit("search-term", {
        search: null,
        company_type: this.company_type,
        sfc_code: this.sfc_code,
        status: this.status,
        formulation_date: this.formulation_date,
        answer_status: this.answer_status,
      });
    },
    getParamsHandler() {
      // Obtener la cadena de consulta de la URL
      const queryParams = window.location.search;

      // Crear un objeto URLSearchParams para analizar los parámetros de la URL
      const params = new URLSearchParams(queryParams);

      // Verificar si el parámetro 'success' tiene valor 'true'
      if (params.get("success") === "true") {
        // Eliminar el parámetro 'success'
        params.delete("success");

        // Actualizar la URL sin el parámetro 'success'
        const newUrl = window.location.pathname + "?" + params.toString();
        window.history.replaceState({}, document.title, newUrl);

        this.showConfirm = true;
      }
    },
    async getRecommendationsStatus() {
      let { error, data } = await this.$api.getRecommendationsStatus();
      if (error) {
        this.statuses = [];
        this.$notification["error"]({
          message: "Resultado",
          description: "Error consultando la lista de estados.",
          onClick: () => { },
        });
      }
      if (data) {
        this.statuses = data.results;
      }
    },
  },
  data() {
    return {
      showConfirm: false,
      user: null,
      reason: null,
      company_type: null,
      id_company: null,
      company: null,
      status: null,
      answer_status: null,
      formulation_date: null,
      statuses: [],
      answer_statuses: [
        {
          id: 1,
          name: "Oportuno",
        },
        {
          id: 2,
          name: "Fuera de término",
        },
      ],
      visible_drawer: false,
      placement: 'right',
      value_radio: 1,
      checkedList_action_plan: ['Sí', 'No'],
      checkedList_progress_report: ['Sí', 'No'],
    };
  },
  computed: {
    optionsCompanies: function () {
      return this.$store.state.form?.optionsCompanies;
    },
  },
  watch: {
    company: {
      handler(newVal, oldVal) {
        if (newVal) {
          let company = this.optionsCompanies.find((company) => {
            return company.id === parseInt(newVal);
          });
          this.sfc_code = company.sfc_code;
          this.company_type = company.company_type;
        } else {
          this.sfc_code = null;
          this.company_type = null;
        }
      },
      deep: true,
    },
  },
  components: { Wrapper },
};
</script> 