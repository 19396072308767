<template>
  <div v-bind="$attrs">
    <h1 class="text-center text-2xl text-blue-dark font-bold">Seguimientos</h1>
    <p class="text-justify text-gray-500 font-bold my-5">
      Relación de los elementos y evidencias que tiene en cuenta la DCF para
      considerar que el plan de acción se encuentra cumplido y que la
      recomendación se puede cerrar Descriptivo
    </p>
    <a-form-model
      ref="formModel"
      :rules="rules"
      :model="form"
      @submit="onSubmit"
      @submit.native.prevent
    >
      <a-form-model-item prop="closed_description">
        <BaseTextarea
          :required="true"
          :maxlength="1000"
          label="Descripción"
          rows="3"
          v-model="form.closed_description"
        />
      </a-form-model-item>
      <a-form-model-item prop="files">
        <DragDrop
          label="Anexar archivos de soporte"
          buttonLabel="SELECCIONE SUS ARCHIVOS"
          id="support_files"
          :fileList="form.files"
          @files-handler="setFiles"
          :required="true"
          maxMB="20"
        />
      </a-form-model-item>
      <a-form-item class="flex gap-6 justify-center my-5">
        <a-button
          :loading="loading"
          type="primary"
          shape="round"
          html-type="submit"
          >GUARDAR</a-button
        >
        <a-button
          :loading="loading"
          type="danger"
          shape="round"
          ghost
          @click="$emit('on-cancel')"
          >CANCELAR</a-button
        >
      </a-form-item>
    </a-form-model>
  </div>
</template>

<script>
export default {
  name: "CloseForm",
  props: {
    recommendationId: {
      type: [String, Number],
      required: true,
    },
  },
  data: (vm) => ({
    loading: false,
    form: {
      closed_description: "",
      files: [],
    },
    rules: {
      closed_description: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          min: 3,
          max: 1000,
          message: "Este campo debe contener entre 3 y 1000 caracteres",
          trigger: "blur",
        },
      ],
      files: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
      ],
    },
  }),
  methods: {
    setFiles(files) {
      this.form.files = files;
    },
    onSubmit(e) {
      e.preventDefault();
      this.$refs.formModel.validate((valid) => {
        if (valid) {
          this.onCreate();
        } else {
          return false;
        }
      });
    },
    onCreate() {
      this.loading = true;
      this.$api
        .closeRecommendation(this.recommendationId, {
          form: {
            closed_description: this.form.closed_description,
          },
          files: this.form.files,
        })
        .then((response) => {
          this.$refs.formModel.resetFields();
          this.showToast("success", "Recomendación cerrada correctamente.");
          this.$emit("on-success", response.data);
        })
        .catch((err) => {
          this.showToast(
            "info",
            "Algunos datos no se guardaron correctamente."
          );
          this.$emit("on-cancel");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
};
</script>